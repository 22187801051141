
.Food {
  padding: 25px;
}

.Food h1 {
  font-size: 18px;
}

.Food__counter {
  font-size: 28px;
  text-decoration: bold;
  text-align: center;
}

.Food__counter--negative {
  color: red;
}

.Food button {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 7px 1px #e1e3e4;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  cursor: pointer;
  text-align: left;
}

.Food button .value {
  float: right;
}

.Food__section {
  margin-bottom: 10px;
}

.Food .events {
  margin-top: 25px;
}

.Food .event {
  border-left: 3px solid #4ec2f9;
  padding: 10px 25px 10px 25px;
  cursor: pointer;
}

.Food .event:hover {
  background-color: #e9f0f5;
  border-left-color: #ff5722;
}

.Food .event .value {
  float: right;
}
