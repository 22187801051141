.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.svg {
  display: inline-block;
  fill: currentColor;
  max-width: 100%;
  position: relative;
  user-select: none;
}