
.period {
  padding-top: 10px;
  width: 100%;
  text-align: center;
}

.success {
  color: green;
  font-weight: bold;
}

.dates {
  padding-left: 25px;
  padding-bottom: 50px;
}

.date {
  margin-bottom: 5px;
}

.DataPointBtnList {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.DataPointBtn {
  height: 40px;
  cursor: pointer;
  margin: 5px;
  background-color: white;
  box-shadow: 1px 1px 7px 1px #e1e3e4;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  padding: 0 15px;
}

.DataPointBtn.active {
  font-weight: bold;
}