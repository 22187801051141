
.Progress {
  position: relative;
  display: flex;
  height: 20px;
  overflow: hidden;
  font-size: 11px;
  background-color: #e9ecef;
  border-radius: 5px;
  text-align: center;
}

.Progress__label {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 4px;
}

.ProgressBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #03a9f4;
  -webkit-transition: width .6s ease;
  transition: width .6s ease;
}

.ProgressBar--green {
  background-color: #4caf50;
}