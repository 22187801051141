
.Router {

}

.Router__header {
  border-bottom: 1px solid #e8e8e8;
  height: 50px;
  width: 100%;
  background-color: white;
  box-shadow: 1px 1px 8px 6px #efefef;
  text-align: left;
}

.Router__header button {
  border: none;
  width: 50px;
  height: 50px;
  padding-left: 15px;
  cursor: pointer;
  background-color: transparent;
}

.Router__menu {
  z-index: 1000;
  position: absolute;
  background-color: white;
  width: 465px;
  height: 100%;
  text-align: center;
}

.Router__menu .link {
  display: block;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  color: #64a7b9;
}

/* Background: */
/* #f5fafe */

/* Box border */
/* #a3e6f8 */