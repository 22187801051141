
.Pomodoro {
  padding: 25px;
}

.Pomodoro h1 {
  font-size: 18px;
}

.Pomodoro input {
  margin-bottom: 10px;
  width: 100%;
  max-width: 100%;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding-left: 10px;

  box-sizing: border-box;
}

.Pomodoro button {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 7px 1px #e1e3e4;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  cursor: pointer;
}

.Pomodoro__section {
  margin-bottom: 10px;
}

.Pomodoro__error {
  color: #ff5722;
}

.Pomodoro label {
  display: inline-block;
  margin-bottom: 10px;
}
