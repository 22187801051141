.Tasks {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.Tasks h1 {
  margin: 0 0 15px 0;
  font-size: 16px;
}

.Tasks button {
  float: right;
}

.Tasks__expanded {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
  padding-left: 10px;
}

.Tasks__utils {
  margin-top: 25px;
}

.Task {

}

.Task--complete {
  text-decoration: line-through;
}

.Task__title {
  position: relative;
  width: 350px;
  max-width: 350px;
  border: 0;
  border-left: 4px solid #ccc;
  padding-left: 10px;
}

.TaskSummary {

}

.TaskSummary--complete {
  text-decoration: line-through;
}