
.GoalPage {
  padding: 25px;

}

.GoalPage input,
.GoalPage textarea {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
}

.GoalPage button {
  padding: 8px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
}

.GoalPage .saveBtn {
  margin-right: 10px;
}

.advanced button {
  border: none;
  background-color: transparent;
  margin-top: 25px;
  outline: none;
}