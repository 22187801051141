
.Dashboard {
  position: relative;
}

.Dashboard__score {
  padding: 25px;
  text-align: center;
}

.Dashboard__item {
  font-size: 45px;
}

.Dashboard__description {
  font-size: 13px;
}

.Date {
  position: absolute;
  right: 5px;
  top: 5px;
  display: inline-block;
  padding: 10px;
  text-align: center;
  font-size: 15px;
  color: #ccc;
}

.Date__modify {
  font-size: 22px;
  cursor: pointer;
  margin-bottom: 5px;
  color: #ccc;
  border: none;
  background: inherit;
  font-weight: normal;
}

.Date__date {
  position: relative;
  bottom: 2px;
}

.Notes {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.Reminders {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.Focus {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.Activities {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

