
.Habits {
  text-align: center;
}

.Habits__list {
  display: flex;
  flex-wrap: wrap;
}

.Habitbox {
  display: block;
  position: relative;
  border: 1px solid #d5e8f7;
  margin: 5px;
  width: 80px;
  height: 80px;
  font-size: 11px;
  box-shadow: 1px 1px 7px 1px #e1e3e4;
  background-color: white;
  text-align: left;
  user-select: none;
}

.Habitbox svg {
  stroke: #ccc;
}

.Habitbox.due {
  border-color: #03a9f4;
  background-color: #dbe7f0;
}

.Habitbox.done {
  border-color: #8bc34a;
  background-color: #e9ffea;
}
.Habitbox.done .icon svg {
  stroke: #8bc34a;
}

.Habitbox:hover {
  /* border-color: #9dd2fd; */
  cursor: pointer;
}

.Habitbox__title {
  position: absolute;
  display: inline-block;
  bottom: 5px;
  left: 8px;
}

.Habitbox .icon {
  position: absolute;
  top: 14px;
  left: 19px;
}
