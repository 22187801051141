
.Trackers {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 5px 5px;
}

.Tracker {
  border: 1px solid #ccc;
  padding: 10px;
  width: 50%;
  margin-right: 5px;
  text-align: center;
  box-shadow: 1px 1px 7px 1px #e1e3e4;
  background-color: white;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  color: black;
}
