.Goals {
  display: block;
  padding: 25px;
}

.Goals__header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.Goals__actions {
  padding-top: 6px;
}

.Goals__actions a {
  padding: 8px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  color: black;
}

.Goals__header button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Goals__header h1 {
  font-size: 16px;
}

.Goal {
  display: block;
  margin: 20px 0;
}

.Goal__top {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.Goal__completed {
  color: green;
  margin-right: 10px;
}

.Goal__bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.Goal__title {
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.Goal__progress {
  font-size: 11px;
  min-width: 120px;
}

.Goal__deadline {
  font-size: 11px;
  min-width: 120px;
}

.Goal__actions {
  width: 100%;
  text-align: right;
}

.Goal__actions a {
  text-decoration: none;
  color: black;
}

.Goal__actions button {
  padding: 2px 8px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  color: black;
  margin: 0 2px;
}

.Goal__actions button:active {
  box-shadow: inset 1px 1px 2px #ccc;
  outline: none;
}
